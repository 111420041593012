<script lang="ts" setup>
useHead({
  bodyAttrs: {
    style: "max-width: 210mm",
  },
});
</script>

<template>
  <main>
    <slot />
  </main>
</template>

<style lang="scss" scoped>
main {
  @apply text-sm;
}
</style>
